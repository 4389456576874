










































import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {PayloadStructure} from "@/model/basic/ModelBasic";
import {mixins} from "vue-class-component";
import {TypePath} from "@/utilities/UtilitiesApp";

export default class extends mixins(ComponentBasic) {
    LN: string = "[PageAdminLogin]::";
    payload: PayloadStructure<Object | null> = PayloadStructure.info(null, '');
    textLogin: string = '';
    textPass: string = '';

    created() {
        this.Log(this.LN + "Created, checing for cookie");
        this.OnLoginCookie()
            .then(user => {
                this.GoToRouter(TypePath.AdminEditSettings.path)
            }).catch(error => {
            this.Log(this.LN + "Rejected." + error);
        });
    }

    onLogin() {
        this.payload = PayloadStructure.loading("Please wait loading..");
        this.Log(this.LN + "Login for [" + this.textLogin + "]");
        this.OnLoginPromise(this.textLogin, this.textPass)
            .then(user => {
                this.Log("Setting User " + user);
                this.payload = PayloadStructure.info("success");
                this.$cookies.set("token", user.getToken());
                this.GoToRouter(TypePath.AdminEditSettings.path);
            })
            .catch(error => {
                console.log("Error");
                console.log(error);
                this.payload = PayloadStructure.error("Failed.. please retry");
                this.textPass = '';
            });

    }
}
